.button_main{
    .button {
        display: inline-block;
        padding: 1rem 2rem 1rem 2rem;
        font-family: 'Comfortaa', sans-serif;
        border-radius: 30rem;
        color: rgb(65, 65, 65);
        font-size: 1rem;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color_jaune; 
          border-radius: 10rem;
          z-index: -2;
        }
        &:before {
          content: '';
          position: absolute; 
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          // background-color: darken($color_jaune, 15%);
          background-color: #4FFFDC;
          transition: all .3s;
          border-radius: 10rem;
          z-index: -1;
        }
        &:hover {
            color: rgb(65, 65, 65);
          &:before {
            width: 100%;
          }
        }
      }
      
      .container {
        padding-top: 50px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
      }
 
      span {
        display: block;
        margin: 2rem 1rem 2rem 1rem; 
        a {
          font-size: .7rem;
          font-family: 'Comfortaa', sans-serif;
          color: #999;
        }
      }
}
