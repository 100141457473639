.popup{
    display: none;
    height: 100%;
    width: 100%;
    position: fixed; 
    top: 0;
    background-color: $color_noir;
    display: flex;
    align-items: center; 
    right: -20vw;
    transition: 0.3s;
    border-left: 2px solid $color_noir;
    z-index: 50;
    ul{
        list-style-type: none; 
        li{
            color: $color_blanc;
            margin-bottom: 3rem;
            font-size: 35px;
        }
    }
}

.show-nav{
    right: -120vw!important; 
    transition: 0.3s; 
}

// .show-nav .burger_bar::before{
//     transform: rotate(45deg); 
// } 

@media screen and (max-width: 799px) {
    .burger_menu{
        display: block;
        display: flex;
        align-items: center; 
        justify-content: space-between;
        padding: 1rem; 
        height: 3.5vh; 
        .bg_blanc{
            background-color: white;
            position: fixed;
            top: 0;
            right: 0; 
            width: 100%;
            height: 8vh; 
        }
        .logo{
            transform: scale(0.9);
            transform-origin: left top;
            margin: 0;
            padding: 0;  
            top: 10px; 
        }
        .logo, .navbar_burger, .burger_bar{
            position: fixed;
        }
    }
}

@media screen and (min-width: 800px) {
    .burger_menu{
        display: none;
    }
    
}

/*-----*/

.nom_tel{
    position: fixed;
    top: 10px;
    right: 50%;
    transform: translate(50%);
    font-weight: 600;
}

.navbar_burger{
    width: 40px;
    height: 40px;
    border: none; 
    position: fixed;
    z-index: 999;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 12px;
}

@media screen and (max-width: 799px){
    .navbar_burger{  
        cursor: pointer;
        .burger_bar, .burger_bar::before, .burger_bar::after{
            display: block;
            width: 40px; 
            height: 4px;
            border-radius: 4px;
            background-color: $color_noir;
            transition: 0.3s;
        } 
        .burger_bar::before, .burger_bar::after{
            content: "";
            position: absolute; 
        }
        .burger_bar::before{
            transform: translateY(-12px);
        }
        .burger_bar::after{
            transform: translateY(12px);
        }
        .open_close{
            transition: 0.3s; 
            &.burger_bar::before{
                transform: rotate(45deg); 
                background-color: #fff;
            }
            &.burger_bar::after{
                transform: rotate(-45deg);
                background-color: #fff;
            } 
            &.burger_bar{
                background: transparent;
            }
        }
    }
}