.cogelog_details{
    max-width: 750px;
    margin: 0 auto;
    padding: 0 1rem 1rem 1rem;  
    img{
        width: 100%;
    }
    .description_projet{
        margin-top: 3vh;
        & > div{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 4rem;
            h2{
                margin: 0;
                width: 50%;
                font-size: 18px;
            }
            p{
                width: 100%; 
                margin: 0;
                font-size: 18px;
                line-height: 30px;
            }
        }
        a.link_skills{
            text-decoration: none;
            font-weight: 700;
            color: #D51A52; 
        }
    }
    & > a{
        text-decoration: none;  
        margin: auto;
        display: table;
    }
    button{
        background-color: #D51A52; 
        width: 12rem;
        height: 3rem;
        margin: 5rem 0 5rem 0;
        display: block;
        border-radius: 50px;
        border: none;
        text-decoration: none;
        color: white;
        transition: background-color 0.2s ease;
        &:hover{
            color: #D51A52;
            font-weight: 700;
            background-color: white;
            border: 2px solid #D51A52;
            transition: background-color 0.2s ease;
        }
    }
    .ombre{
        box-shadow: 5px 5px 15px #d3d3d3;
    }
}

@media screen and (min-width: 800px){
    .cogelog_details{
        padding: 2rem 1rem 1rem 1rem; 
        img{
            width: 750px; 
        }
        .description_projet{
            margin-top: 8vh;
            & > div{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 4rem;
                h2{
                    margin: 0;
                    width: 20%;
                    font-size: 18px;
                }
                p{
                    width: 80%;
                    margin: 0;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
            & > .vertical_boxes{
                flex-direction: column;
            }
        }
    }
}