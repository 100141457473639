@media screen and (min-width: 800px){
    .contact_page{
        width: 750px;
        margin: auto;
    }
    .contact_title{
        color: white;
        height: 10vh;
        display: flex; 
        justify-content: center;
        align-items: center; 
        flex-direction: column;
        .metier{ 
            font-size: 1rem; 
            text-transform: uppercase;
            color: $color_jaune; 
        }
    } 
}

@media screen and (max-width: 799px){
    .contact_page{
        width: 100%;
        margin: auto; 
        p{
            width: 90%;
            margin: auto;
        }
    }
    input, textarea{
        width: 100%; 
    }
}

.contact_page{
    h1{
        margin-bottom: 4rem;
    } 
}

.container-formulaire{
    margin-top: 4rem; 
}


/*--- Formulaire ---*/

.container-formulaire{
    // height: 50vh;
    display: flex;
    align-items: center;
    position: relative;
    .contact-form{
        width: 100%;
        margin: auto;
        .form-content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            input{
                width: 100%;
                height: 35px;
                border-radius: 5px;
                background-color: $color_blanc;
                padding-left: 1rem; 
                border: 1px solid rgb(99, 99, 99);
                outline: none;
                margin-bottom: 2rem;
                font-family: 'Open Sans', sans-serif;
                font-size: 12px;
            }
            .email-content{
                width: 46%;
                & > input{
                    width: 100%;
                }
            } 
            textarea{
                width: 100%;
                border-radius: 5px; 
                background-color: $color_blanc;
                outline: none; 
                border: 1px solid rgb(99, 99, 99);
                height: 12rem;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 2rem; 
                font-size: 12px;
                resize: none;
                padding: 1rem;
            }
        }
        .button{
            background-color: $color_blanc;
            padding: 1rem 2rem 1rem 2rem;
            border-radius: 30px;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            &:hover{
                background-color: #e2e2e2;  
            }      
        }
    }
}

.email-content{
    position: relative;
}

#not-mail {
    display: none;
    position: absolute; 
    top: 4px;
    color: #ff4d4d;
    right: 0; 
    transform-origin: 50% 50%;
} 

@keyframes dongle {
    0% {
        transform: translateX(55%);
    }
    10% {
        transform: translateX(45%);
    }
    20% {
        transform: translateX(55%);;
    }
    30% {
        transform: translateX(45%); 
    }
    40% {
        transform: translateX(55%);;
    }
    50% {
        transform: translateX(45%);
    }
    60% {
        transform: translateX(55%);;
    }
    70% {
        transform: translateX(45%);
    }
    80% {
        transform: translateX(55%);;
    }
    90% {
        transform: translateX(45%);
    }
    100% {
        transform: translateX(50%);
    }
}

.form-message{
    font-family: 'Open Sans', sans-serif;
    padding: 1rem; 
    border-radius: 5px;
    opacity: 0;
    display: table;
    margin-top: 2rem;
    transform: translateX(50%);
}


@media screen and (max-width: 850px) {
    .container-formulaire{
        .contact-form{
            width: 90%; 
            .form-content{
                input, textarea{
                    width: 95%;
                }
            }
        }
    }
    .form-message{
        font-family: 'Open Sans', sans-serif;
        padding: 1rem; 
        border-radius: 5px; 
        opacity: 0;
        display: table;
        margin-top: 2rem;
        transform: translateX(0);
    }
}