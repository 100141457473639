h1{
    text-align: center;
    font-family: 'Open Sans Light', sans-serif;
    font-weight: 300;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: 8rem; 
}

section.portfolio {
    width: 90%; 
    margin: auto; 
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between; 
} 

article{
    width: 90vw;   
    height: 18vh;   
    margin-bottom: 30px; 
}
 
.skills{
    background-image: url("./../../assets/img/portfolio1_skills.jpg");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out; 
    border-radius: 5px;
    &:hover{
        background-size: 120%; 
        transition: background-size 0.2s ease-in-out;
    } 
}

.app_patients{
    background-image: url("./../../assets/img/portfolio1_app_patients.jpg");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out; 
    border-radius: 5px;
    &:hover{
        background-size: 120%; 
        transition: background-size 0.2s ease-in-out;
    } 
}

.site_cogelog{
    background-image: url("./../../assets/img/portfolio1_site_cogelog.jpg");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out; 
    border-radius: 5px;
    &:hover{
        background-size: 120%; 
        transition: background-size 0.2s ease-in-out;
    } 
}

.site_innovance{
    background-image: url("./../../assets/img/portfolio1_site_innovance.jpg");
    background-size: 100%;
    background-position: bottom; 
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out; 
    border-radius: 5px;
    &:hover{
        background-size: 120%; 
        transition: background-size 0.2s ease-in-out;
    } 
}

.tagasuga{
    background-image: url("./../../assets/img/portfolio1_tagasuga.jpg");
    background-size: 100%;
    background-position: bottom; 
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out; 
    border-radius: 5px;
    &:hover{
        background-size: 120%; 
        transition: background-size 0.2s ease-in-out;
    } 
}


@media screen and (min-width: 800px) and (max-width: 1499px){
    article{
        width: 90vw;   
        height: 40vh;  
        margin-bottom: 50px;
    }
}
 


@media screen and (min-width: 1500px) {
    h1{
        height: 20vh;
    }
    
    section.portfolio {
        width: 80%;
    }
    
    article{
        width: 35vw;   
        height: 30vh;
        margin-bottom: 70px;
    }
}
