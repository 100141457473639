.download_cv{
    display: none!important;
}

@media screen and (min-width: 800px) {
    .download_cv{
        display: block!important;
        background-color: $color_jaune;
        border-radius: 30px;
        position: absolute!important;
        position: fixed!important;
        border: 2px solid $color_noir;
        bottom: 20px;
        right: 20px;
        padding: 1rem 2rem 1rem 2rem;
        outline: none;
        font-family: "Comfortaa";
        a{
            display: block;
            color: $color_noir;
        }
    }
}