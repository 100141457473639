@media screen and (min-width: 800px){ 
    nav{
        display: block;
        &>div{
            width: 90%;
            height: 14vh; 
            margin: auto;
            display: flex;
            justify-content: space-between; 
            align-items: center;  
        }
        .logo{
            width: 40%;
            display: flex;
            justify-content: flex-start;
            & svg{
                height: 100px;
            }
        }
        .nom{
            width: 20%; 
            display: flex;
            justify-content: center;
            & p{
                font-size: 22px;
                font-family: 'Open Sans', sans-serif; 
                font-weight: 600; 
            }
        }
        .pages{
            width: 40%; 
            display: flex;
            justify-content: flex-end;
        }
        ul{ 
            width: 250px; 
            display: flex; 
            justify-content: space-between; 
            margin: 0;
            list-style-type: none;
            @keyframes shine {
                from {
                -webkit-mask-position: 150%; 
                }
                to {
                -webkit-mask-position: -50%;  
                }
            }
            a{
                text-decoration: none;
                color: $color_blanc;
                &:visited {
                    color: $color_blanc; 
                }
                &.nav-active{
                    font-weight: 500;
                    color: $color_jaune;
                }
                &.hover:hover{
                    /* effect-shine */
                    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                    -webkit-mask-size: 200%;
                    animation: shine 2s infinite;
                }
            }
        }
    }
}

@media screen and (min-width: 200px) and (max-width: 799px){
    nav{
        display: none;
        height: 160px;
        // background-color: $color_noir;
    }
}

