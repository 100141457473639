.mentions_legales{
    h1, h2, p, ul, li{
        color: $color_blanc;
    }
    ul{
        list-style-type: none;
        padding: 0;  
    }
}

.mentions_legales{
    width: 90%; 
    margin: auto; 
    margin-top: 8rem!important;
    margin-bottom: 3rem!important; 
}

@media screen and (min-width: 800px) {
    .mentions_legales{
        width: 70%; 
        margin: auto; 
    }
}