/*Variables*/

$color_beige: #FAEFDA;
$color_jaune: #FFD45C;
$color_noir: #070c11;
$color_blanc: #FCFCFC;
$color_gris: #FFFFFF;
$color_rouge: #B2312F;

/*Variables fin*/

.nav-active{
    color: #0235FA!important; 
    li{
        color: #0235FA!important;
    }
}

/*Fonts*/

// font-family: 'Cantarell', sans-serif;
// font-family: 'Work Sans', sans-serif; 

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

ul, li, p, h1, h2, h3, a{
    // font-family: 'Comfortaa', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #000;
}

#cv_menu_mobile{
    color: white;
    text-decoration: none;
}

#cv_menu{
    color: black;
    text-decoration: none;
}

img{
    border-radius: 5px; 
}

h1{
    margin: 100px 0px 100px 0px!important;  
}

/*Taille du H1*/

@media screen and (min-width: 200px){
    h1{
        font-size: 6vw!important;
    } 
}
 
@media screen and (min-width: 1000px){
    h1{
        font-size: 3vw!important;
    } 
}

@media screen and (min-width: 1600px){
    h1{
        font-size: 2.5vw!important;
    }
}

a{
    color: #000;
}

/* Font fin */

.logo{
    width: 60px;  
} 

html{
    background-color: #fff;
}

.home{ 
    width: auto;
    margin: auto;
}

/* width */ 
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color_blanc; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #afbac0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #646464; 
}